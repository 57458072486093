
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import Countries from '@/mixins/Countries';
import { SelectItem } from '@/interfaces/components/SelectItem';
import { Country } from '@/enums/Country';

@Component({
  components: { VFormBuilder },
})
export default class BasicForm extends mixins(StackedForm, Countries) {
  @Prop({ type: Array, required: false, default: () => Object.values(Country) }) public allowedCountries!: string[];

  get items() {
    return [
      { name: 'name', type: InputType.Text, label: 'venue.form.name', rules: 'required' },
      {
        name: 'country',
        type: InputType.Select,
        label: 'venue.form.country',
        rules: 'required',
        items: this.countries.filter((item: SelectItem) => this.allowedCountries.includes(item.value as string)),
        change: this.changedCountry,
      },
      {
        name: 'street',
        type: InputType.Text,
        label: 'venue.form.street',
        rules: 'required',
      },
      {
        name: 'number',
        type: InputType.Text,
        label: 'venue.form.number',
        rules: 'required',
      },
      {
        name: 'city',
        type: InputType.Text,
        label: 'venue.form.city',
        rules: 'required',
      },
      {
        name: 'postalCode',
        type: InputType.Text,
        label: 'venue.form.postalCode',
        rules: 'required',
      },
      {
        name: 'orderEmail',
        type: InputType.Text,
        label: 'venue.form.storeEmail',
        rules: 'required|email',
      },
      {
        name: 'phoneNumber',
        type: InputType.Text,
        label: 'venue.form.phone',
        rules: 'required',
      },
    ];
  }

  public async changedCountry(country: Country) {
    if (country) {
      this.$emit('country', country);
    }
  }
}
