
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import DeliveryPostalCode from '@/components/venue/form/DeliveryPostalCode.vue';
import { MasterType } from '@/enums/MasterType';

@Component({
  components: { DeliveryPostalCode, VOpeningHour, VFormBuilder },
})
export default class SettingsForm extends mixins(StackedForm) {
  @Prop({ type: Boolean, default: false }) public disableDelivery!: boolean;

  get masterTypes() {
    return [
      {
        value: MasterType.TERMINAL,
        text: this.$t('masterTypes.terminal'),
      },
      {
        value: MasterType.WEB,
        text: this.$t('masterTypes.web'),
      },
    ];
  }

  get items() {
    return [
      {
        name: 'preorderTakeAway',
        type: InputType.Checkbox,
        label: 'venue.form.preorderTakeAway',
        default: true,
        disabled: this.isDisabledPreorderCallback,
      },
      {
        name: 'deliveryEnabled',
        type: InputType.Checkbox,
        label: 'venue.form.preorderDelivery',
        default: false,
        disabled: this.isDisabledPreorderCallback,
        visible: !this.disableDelivery,
      },
      {
        name: 'deliveryByRadius',
        type: InputType.Checkbox,
        label: 'venue.form.deliveryByRadius',
        default: true,
        disabled: this.isDisabledDeliveryCallback,
        visible: !this.disableDelivery,
      },
      { name: 'deliveryPostalCodes', type: InputType.Hidden, default: [] },
      {
        name: 'deliveryRadius',
        type: InputType.Slider,
        label: 'venue.form.deliveryRadius',
        min: 1,
        max: 20,
        default: 20,
        visible: this.activeDeliveryTypeCallback && !this.disableDelivery,
        disabled: this.isDisabledDeliveryCallback,
      },
      {
        name: 'movDelivery',
        type: InputType.Text,
        label: 'venue.form.movDelivery',
        default: 15,
        visible: !this.disableDelivery,
        disabled: this.isDisabledDeliveryCallback,
      },
    ];
  }

  public isDisabledPreorderCallback(form: { masterType: MasterType }) {
    return form.masterType === MasterType.TERMINAL;
  }

  public activeDeliveryTypeCallback(form: { deliveryByRadius?: boolean; masterType: MasterType }) {
    return form.deliveryByRadius !== false && form.masterType === MasterType.TERMINAL;
  }

  public isDisabledDeliveryCallback(form: { deliveryEnabled: boolean; masterType: MasterType }) {
    if (this.disableDelivery) {
      return true;
    }
    if (form) {
      return !form.deliveryEnabled && form.masterType === MasterType.TERMINAL;
    }
  }

  public getData() {
    if (this.$refs.deliveryHours && this.$refs.deliveryHours.getHours()) {
      return {
        ...this.$refs.form.form,
        deliveryHours: this.$refs.deliveryHours.getHours(),
      };
    }

    return {
      ...this.$refs.form.form,
    };
  }

  public setData(data: any) {
    this.$refs.form.setData(data);
    if (data.deliveryHours) {
      this.$nextTick(() => {
        this.$refs.deliveryHours.setHours(data.deliveryHours, true);
      });
    }
  }
}
