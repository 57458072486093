
import { Component } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';

interface PaymentFormModel {
  vrPaymentEnabled: boolean;
  ppEnabled: boolean;
  ppEmail: string;
}

@Component({
  components: { VFormBuilder },
})
export default class PaymentForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { getData: () => PaymentFormModel };
  };

  get items() {
    return [
      { name: 'vrPaymentEnabled', type: InputType.Checkbox, label: 'venue.form.vrPaymentEnabled' },
      { name: 'ppEnabled', type: InputType.Checkbox, label: 'venue.form.ppEnabled' },
      { name: 'ppEmail', type: InputType.Text, label: 'venue.form.ppEmail', rules: this.getPayPalEmailRules },
    ];
  }

  public getPayPalEmailRules(form: PaymentFormModel) {
    if (form.ppEnabled) {
      return 'required|email';
    }

    return 'email';
  }
}
