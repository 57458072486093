
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import BasicForm from '@/components/auth/venue/BasicForm.vue';
import InvoiceReceiverForm from '@/components/auth/venue/InvoiceReceiverForm.vue';
import OpeningHourForm from '@/components/venue/form/OpeningHourForm.vue';
import SettingsForm from '@/components/auth/venue/SettingsForm.vue';
import ProvisionForm from '@/components/auth/venue/ProvisionForm.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import VenueApiService from '@/api/http/VenueApiService';
import { namespace } from 'vuex-class';
import { User } from '@/interfaces/models/User';
import CopyBtn from '@/components/venue/CopyBtn.vue';
import { HereApiService, Location } from '@/api/http/HereApiService';
import { CustomerGroup } from '@/enums/CustomerGroup';
import { MasterType } from '@/enums/MasterType';
import DocumentForm from '@/components/venue/form/DocumentForm.vue';
import PaymentForm from '@/components/auth/venue/PaymentForm.vue';
import { Country } from '@/enums/Country';

const auth = namespace('auth');
const app = namespace('app');

@Component({
  components: {
    PaymentForm,
    DocumentForm,
    CopyBtn,
    ProvisionForm,
    SettingsForm,
    OpeningHourForm,
    InvoiceReceiverForm,
    BasicForm,
    VWrapper,
  },
})
export default class CreateSimpleVenue extends mixins(StackedForm, Notification) {
  @auth.State('user') public user!: Partial<User>;

  @app.State('venues') public venues!: Venue[];

  public country: Country | null = null;

  public save() {
    this.validate().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        const venues: Array<Partial<Venue>> = [await this.getMergedData()];
        const api: VenueApiService = new VenueApiService();
        await api.register({
          venues,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.user.email,
          customerGroup: this.$getLinkedCustomerGroup(),
          user: this.user.id,
          phoneNumber: this.$refs.basic.getData().phone,
        });
        this.$router.push({ name: 'venue.index' });
        this.notifyInfo('register.notification.venue');
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  get shouldDisableRegister() {
    return (
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayFr ||
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayLu
    );
  }

  get allowedCountries() {
    if (this.$getLinkedCustomerGroup() === CustomerGroup.Subway) {
      return [Country.DE, Country.AU];
    } else if (this.$getLinkedCustomerGroup() === CustomerGroup.SubwayFr) {
      return [Country.FR];
    } else if (this.$getLinkedCustomerGroup() === CustomerGroup.SubwayLu) {
      return [Country.LU];
    }

    return [Country.DE, Country.AU];
  }

  get allowedLegalTypes() {
    if (
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayFr ||
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayLu
    ) {
      return ['sa', 'sarl', 'eurl', 'sas', 'autres'];
    }

    return null;
  }

  get shouldDisableDelivery() {
    return (
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayFr ||
      this.$getLinkedCustomerGroup() === CustomerGroup.SubwayLu
    );
  }

  public async getMergedData() {
    return {
      ...this.$refs.invoiceReceiver.getData(),
      ...this.$refs.basic.getData(),
      ...this.$refs.openingHours.getData(),
      ...this.$refs.settings.getData(),
      ...this.$refs.provision.getData(),
      ...this.$refs.documents.getData(),
      ...this.$refs.payment.getData(),
      city: {
        de: this.$refs.basic.getData().city,
      },
      masterType: MasterType.WEB,
      invoiceReceiver: {
        ...this.$refs.invoiceReceiver.getData(),
      },
      location: await this.getGeoCoordinates({
        ...this.$refs.basic.getData(),
        city: this.$refs.basic.getData().city,
      }),
      vatNumber: this.$refs.invoiceReceiver.getData().vatNumber,
      legalForm: this.$refs.invoiceReceiver.getData().legalForm,
      email: this.$refs.invoiceReceiver.getData().email,
    };
  }

  get firstName() {
    if (this.user) {
      return this.user.name!.split(' ')[0];
    }

    return '';
  }

  get lastName() {
    if (this.user) {
      const nameParts: string[] = this.user.name!.split(' ');
      return nameParts.splice(1, nameParts.length).join(' ');
    }

    return '';
  }

  public copy(venue: Venue, type: 'settings' | 'openingHours' | 'invoiceReceiver' | 'payment') {
    switch (type) {
      case 'settings':
        const deliveryEnabled: boolean = venue.deliveryEnabled;
        if (!deliveryEnabled) {
          this.$refs.settings.setData({ deliveryEnabled: false, preorderTakeAway: venue.preorderTakeAway });
        } else {
          this.$refs.settings.setData({
            deliveryEnabled: true,
            preorderTakeAway: venue.preorderTakeAway,
            deliveryRadius: venue.deliveryRadius,
            movDelivery:
              // @ts-ignore
              venue.movDelivery && typeof venue.movDelivery === 'object' ? venue.movDelivery.$numberDecimal : 15,
            deliveryHours: venue.deliveryHours ? venue.deliveryHours : [],
          });
        }
        break;
      case 'invoiceReceiver':
        if (venue.invoiceReceiver) {
          this.$refs.invoiceReceiver.setData({
            ...venue.invoiceReceiver,
            legalForm: venue.legalForm,
            email: venue.email,
            vatNumber: venue.vatNumber,
          });
        }
        break;
      case 'openingHours':
        if (venue.openingHours) {
          this.$refs.openingHours.setData(venue.openingHours);
        }
        break;
    }
  }

  public async getGeoCoordinates(form: { street: string; number: string; postalCode: string; city: string }) {
    const location: Location | null = await HereApiService.getGeoCoordinates(form);

    if (location) {
      return {
        coordinates: [location.lng, location.lat],
        type: 'Point',
      };
    }

    return null;
  }

  public async changedCountry(country: Country) {
    this.country = country;
  }
}
