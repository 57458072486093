
import { Component, Model, Prop } from 'vue-property-decorator';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import { SelectItem } from '@/interfaces/components/SelectItem';
import { Country } from '@/enums/Country';
import { FormItem } from '@/interfaces/components/FormItem';

@Component({
  components: { VFormBuilder },
})
export default class InvoiceReceiverForm extends mixins(StackedForm) {
  @Model() public country!: string;
  @Prop({ type: Array, default: null }) public allowedLegalTypes!: string[] | null;

  protected useVatNumberGermany: boolean = false;

  get items(): FormItem[] {
    return [
      { name: 'fullName', type: InputType.Text, label: 'venue.form.fullName', rules: 'required' },
      {
        name: 'street',
        type: InputType.Text,
        label: 'venue.form.street',
        rules: 'required',
      },
      {
        name: 'number',
        type: InputType.Text,
        label: 'venue.form.number',
        rules: 'required',
      },
      {
        name: 'city',
        type: InputType.Text,
        label: 'venue.form.city',
        rules: 'required',
      },
      {
        name: 'postalCode',
        type: InputType.Text,
        label: 'venue.form.postalCode',
        rules: 'required',
      },
      {
        name: 'legalForm',
        type: InputType.Select,
        label: 'venue.form.legalForm',
        rules: 'required',
        items: this.legalForms,
      },
      {
        name: 'email',
        type: InputType.Text,
        label: 'venue.form.email',
        rules: 'required|email',
      },
      {
        name: 'vatNumber',
        type: InputType.Text,
        label: 'venue.form.vatNumber',
        rules: 'required|alpha_num|uppercase|min:5|max:20|vat_number',
        visible: !this.showVatNumberGermanyOption || !this.useVatNumberGermany,
      },
      {
        name: 'vatNumberGermany',
        type: InputType.Text,
        label: 'venue.form.vatNumberGermany',
        rules: 'required|min:5|max:20|vat_number_germany',
        visible: this.showVatNumberGermanyOption && this.useVatNumberGermany,
      },
    ];
  }

  get legalForms(): SelectItem[] {
    const legalForms: SelectItem[] = [
      { text: this.$t('legalForms.ag'), value: 'ag' },
      { text: this.$t('legalForms.gmbh'), value: 'gmbh' },
      { text: this.$t('legalForms.ltd'), value: 'ltd' },
      { text: this.$t('legalForms.kg'), value: 'kg' },
      { text: this.$t('legalForms.pe'), value: 'pe' },
      { text: this.$t('legalForms.other'), value: 'other' },
      { text: this.$t('legalForms.sa'), value: 'sa' },
      { text: this.$t('legalForms.sarl'), value: 'sarl' },
      { text: this.$t('legalForms.eurl'), value: 'eurl' },
      { text: this.$t('legalForms.sas'), value: 'sas' },
      { text: this.$t('legalForms.autres'), value: 'autres' },
    ];

    if (this.allowedLegalTypes) {
      return legalForms.filter((i: SelectItem) => this.allowedLegalTypes!.includes(i.value as string));
    }

    return legalForms;
  }

  get showVatNumberGermanyOption(): boolean {
    return !!(this.country && this.country === Country.DE);
  }

  public toggleVatNumberGermany(): void {
    this.useVatNumberGermany = !this.useVatNumberGermany;
  }
}
