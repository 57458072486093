
import { Component, Vue } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
@Component({
  components: { VFormBuilder },
})
export default class ProvisionForm extends mixins(StackedForm) {
  get items() {
    return [
      {
        name: 'sendProvisionReportPeriod',
        type: InputType.Select,
        default: 'never',
        label: 'venue.form.sendProvisionReportPeriod',
        items: this.periods,
      },
    ];
  }

  get periods() {
    return [
      { text: this.$t('periods.never'), value: 'never' },
      { text: this.$t('periods.daily'), value: 'daily' },
      { text: this.$t('periods.weekly'), value: 'weekly' },
      { text: this.$t('periods.monthly'), value: 'monthly' },
    ];
  }
}
